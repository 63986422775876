import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        contentfulSiteMetaData {
          title 
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.contentfulSiteMetaData.title} />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          <main>{children}</main>
          <footer>
            © {new Date().getFullYear()}, Created with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>,&nbsp;
            <a href="https://www.contentful.com">Contentful</a> and&nbsp;
            <a href="https://www.netlify.com">Netlify</a>
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
